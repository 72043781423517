exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lite-js": () => import("./../../../src/pages/lite.js" /* webpackChunkName: "component---src-pages-lite-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-baseball-team-manager-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/baseballTeamManager/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-baseball-team-manager-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-langchain-experiments-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/langchainExperiments/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-langchain-experiments-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-map-app-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/mapApp/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-map-app-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-metronome-app-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/metronomeApp/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-metronome-app-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-mood-on-repeat-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/moodOnRepeat/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-mood-on-repeat-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-personal-website-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/personalWebsite/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-personal-website-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-strava-wrapped-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/projects/stravaWrapped/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-strava-wrapped-index-mdx" */)
}

